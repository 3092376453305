<!-- eslint-disable vue/attributes-order -->
<template>
  <DatePicker
    v-model="datePickerValue"
    :view-mode="props.viewMode"
    :max="props.max"
  >
    <template #activator="{ props: { onClick } }">
      <TextField
        :get-input-ref="props.getInputRef"
        v-model="inputValue"
        v-maska="dateOptions"
        :class="props.className"
        :label-key="props.labelKey ? $t(props.labelKey) : undefined"
        :hide-details="props.hideDetails"
        :clearable="props.clearable"
        append-inner-icon="i-mdi:calendar"
        validate-on="lazy submit"
        @click:clear="onDateCleared"
        @click:append-inner="onClick"
        :required="props.required"
        :rules="props.rules"
        :placeholder="dateFormat.toLowerCase()"
        @change="setValue"
        v-bind="$attrs"
      />
    </template>
  </DatePicker>
</template>

<script lang="ts" setup>
import type { DateInput } from '@/types/global';
import { dateMaskOptions } from '@/constants/maskOptions';
import { useUserStore } from '@/store/user';
import type { DatePickerViewMode } from '@/constants/datePicker';
import type { VNodeRef } from 'vue';

type Props = {
  modelValue: DateInput;
  labelKey?: string;
  className?: string;
  clearable?: boolean;
  hideDetails?: boolean;
  required?: boolean;
  rules?: Array<(v: string) => true | string>;
  viewMode?: DatePickerViewMode;
  max?: DateInput;
  getInputRef?: VNodeRef;
};
type Emits = {
  (e: 'update:modelValue', value: string | null): void;
  (e: 'dateCleared'): void;
};

const props = withDefaults(defineProps<Props>(), {
  labelKey: undefined,
  className: '',
  clearable: false,
  hideDetails: false,
  required: false,
  rules: () => [],
  viewMode: undefined,
  max: null,
  getInputRef: undefined,
});
const emit = defineEmits<Emits>();
const { formatDate, formatToISODate } = useDateUtils();
const { dateValidator } = useRule();

const { dateFormat } = useUserStore();

const dateOptions = reactive(dateMaskOptions(dateFormat));

const datePickerValue = computed({
  get: () => (props.modelValue ? new Date(props.modelValue) : null),
  set(value) {
    emit('update:modelValue', value ? formatToISODate(value) : null);
  },
});
const inputValue = computed(() => formatDate(props.modelValue));

const setValue = (value?: string) => {
  emit(
    'update:modelValue',
    value && dateValidator(value) === true ? formatToISODate(value) : null,
  );
};

const onDateCleared = () => {
  emit('update:modelValue', null);
  emit('dateCleared');
};
</script>

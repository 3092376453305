<template>
  <v-menu
    v-model="menuOpened"
    transition="slide-y-transition"
    :close-on-content-click="false"
  >
    <template #activator="{ props: activatorProps }">
      <slot name="activator" :props="activatorProps" />
    </template>
    <template #default="{ isActive }">
      <v-date-picker
        v-model="datePickerValue"
        color="primary"
        header=""
        width="360"
        :view-mode="viewMode"
        :max="max"
        @update:model-value="isActive.value = false"
        @update:month="setMonth"
      />
    </template>
  </v-menu>
</template>

<script lang="ts" setup>
import { type DateInput } from '@/types/global';
import { DatePickerViewMode } from '@/constants/datePicker';
import { startOfMonth } from 'date-fns';

type Props = {
  modelValue: DateInput;
  viewMode?: DatePickerViewMode;
  max?: DateInput;
};

type Emits = {
  (e: 'update:modelValue', value: Date): void;
};

const props = withDefaults(defineProps<Props>(), {
  viewMode: DatePickerViewMode.Month,
  max: null,
});
const emit = defineEmits<Emits>();

const menuOpened = ref(false);

const datePickerValue = computed({
  get() {
    return props.modelValue || props.max;
  },
  set(value) {
    emit('update:modelValue', value as Date);
  },
});

const setMonth = (value: number) => {
  if (props.viewMode === DatePickerViewMode.Months) {
    const today = new Date();
    today.setMonth(value);
    emit('update:modelValue', startOfMonth(today));

    menuOpened.value = false;
  }
};
</script>
